import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Img from "gatsby-image"

import "./donation.css"

const DoNation = () => (
  <Layout>
    <SEO title="Artxcode client | Do Nation" />
    <div className="donation-header">
      <div className="donation-header-inner">
        <p className="donation-header-inner-subtitle">Client</p>
        <h1>Do Nation</h1>
      </div>
    </div>
    <div className="donation-content">
      <div className="donation-content-inner">
        <h2>The client</h2>
        <p>
          Do Nation helps people commit to making small behaviour changes that add up to a better world, like cycling to work,
          driving more efficiently, eating less meat or turning the thermostat down a notch.
        </p>
        <p>
          Users can pledge themselves, or start a campaign - at home or at work - to raise pledges from friends, family and colleagues.
        </p>
        <p>
          In the workplace, they help companies engage their employees in sustainability through simple and fun competitions,
          therefore reducing their environmental impact while saving money and increasing staff retention.
        </p>
        <p>
          Some of the organisations that use Do Nation’s platform include Innocent, Network Rail, Siemens, Pret and University College London.
        </p>
        <h2>The work</h2>
        <p>
          Do Nation had taken on a substantial project, Count Us In, as part of a global initiative to mobalise 1 billion people to take a few
          small steps and make them into lifestyle changes. The project had many backers, including IKEA, HSBC, BT, the United Nations,
          Tottenham Hotspur FC, Reckitt Benckiser, Sky UK among many others.
        </p>
        <p>
          Artxcode was contracted to help expand Do Nation’s platform and accelerate the development of new features for this new campaign.
        </p>
        <div className="image-container">
          <Logo name="donation1" />
        </div>
        <p>
          Count Us In was to be launched as part of <a href="https://countdown.ted.com" target="__blank">Countdown</a>, a global initiative by TED to
          champion and accelerate solutions to the climate change crisis. As a result, the project had a hard deadline for completion.
        </p>
        <p>
          Artxcode worked alongside design agencies, as well as the Do Nation team, to help develop the product. The work involved building web
          frontends from scratch according to new designs, refactoring and modernising the codebase, and setting up and performing load tests prior to launch.
          It also required maintaining Django multi-sites and providing updates to the Wagtail Content Management System.
        </p>
        <div className="image-container">
          <Logo name="donation2" />
        </div>
        <p>
          The technologies used consisted of JavaScript, React, CSS/SCS, Python, Django, Django Rest Framework, GraphQL, Docker, and Heroku.
        </p>
        <p>
          The platform received extensive media coverage at launch, some of which you can view below.
        </p>
        <a
          href="https://count-us-in.org"
          target="__blank"
          style={{
            color: `black`, textDecoration: `none`, borderBottom: `1px solid black`, paddingBottom: `0.625rem`,
          }}
        >Visit website</a>
        <div className="pr-container">
          <h2>Coverage</h2>
          <div className="pr">
            <p className="pr-name">TED</p>
            <a className="pr-link" href="https://countdown.ted.com/" target="__blank">TED Countdown</a>
          </div>
          <div className="pr">
            <p className="pr-name">The Guardian</p>
            <a className="pr-link" href="https://www.theguardian.com/environment/2020/oct/10/campaign-seeks-1bn-people-to-save-climate-one-small-step-time" target="__blank">Campaign seeks 1bn people to save climate – one small step at a time</a>
          </div>
          <div className="pr">
            <p className="pr-name">Tottenham Hotspur</p>
            <a className="pr-link" href="https://www.tottenhamhotspur.com/news/2020/october/tottenham-hotspur-becomes-founding-partner-of-count-us-in/" target="__blank">Tottenham Hotspur becomes founding partner of Count Us In</a>
          </div>
          <div className="pr">
            <p className="pr-name">Country Living</p>
            <a className="pr-link" href="https://www.countryliving.com/uk/wildlife/countryside/a34353235/count-us-in-carbon-pollution-pledges/" target="__blank">16 pollution pledges that will lower your carbon footprint immediately</a>
          </div>
          <div className="pr">
            <p className="pr-name">Broadway World</p>
            <a className="pr-link" href="https://www.broadwayworld.com/bwwtv/article/Mark-Ruffalo-Dia-Mirza-Join-Global-Initiative-Aimed-At-Inspiring-1-Billion-to-Act-On-Climate-Change-20201007" target="__blank">Mark Ruffalo, Dia Mirza Join Global Initiative Aimed At Inspiring 1 Billion to Act On Climate Change</a>
          </div>
          <div className="pr">
            <p className="pr-name">The Sun</p>
            <a className="pr-link" href="https://www.thesun.co.uk/money/12893788/join-our-green-team-to-make-big-savings/" target="__blank">Help the environment, make savings & win great prizes with The Sun’s Green Team</a>
          </div>
          <div className="pr">
            <p className="pr-name">Glasgow Times</p>
            <a className="pr-link" href="https://www.glasgowtimes.co.uk/news/18783687.count-us-new-global-environment-campaign-aims-reach-one-billion-people/" target="__blank">Count Us In: New global environment campaign aims to reach one billion people</a>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

const Logo = ({ name }) => {
  const data = useStaticQuery(graphql`
    query {
      donation1: file(relativePath: { eq: "donation1.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
      donation2: file(relativePath: { eq: "donation2.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)

  if (!data?.donation1?.childImageSharp?.fluid && !data?.donation2?.childImageSharp?.fluid) {
    return <div>Picture not found</div>
  }

  if (name === "donation1") {
    return <Img
      fluid={data.donation1.childImageSharp.fluid}
      imgStyle={{
        objectFit: 'contain',
      }}
    />
  }
  else if (name === "donation2") {
    return <Img
      fluid={data.donation2.childImageSharp.fluid}
      imgStyle={{
        objectFit: 'contain',
        marginLeft: '-12px',
      }}
    />
  }

  return null
}

export default DoNation
